<template>
  <div class="myVip-container">
    <!-- 用户信息 -->
    <van-cell-group class="user-info">
      <van-cell class="base-info" center>
        <van-image class="avatar" slot="icon" round :src="userInfo.avatar" />
        <div class="name" slot="title">{{ userInfo.nickname }}</div>
      </van-cell>
    </van-cell-group>
    <!-- 我的vip列表 -->
    <div class="myVip-detail">
      <div class="myVip-detail-top">
        <div class="myVip-title bold">我的VIP</div>
        <!-- 续费记录 -->
        <div class="renew-record">
          <router-link to="/user/renewrecord">续费记录</router-link>
        </div>
      </div>

      <van-loading size="24px" vertical v-show="isLoding"
        >加载中...</van-loading
      >
      <div class="myVip-card" v-if="vipList.length > 0">
        <div class="" v-for="(item, index) in vipList" :key="index">
          <div class="myVip-item" v-if="item.is_valid == 1">
            <div class="myVip-item__top">
              <span class="myVip-title">方案名称</span>
              <span class="myVip-name">{{ item.name }}</span>
              <span class="myVip-date"
                >有效期至：{{ item.valid_date }} 已过期</span
              >
            </div>
            <div class="myVip-item__bot" v-if="item.is_show == 1 && item.type==2">
              <div class="pay-btn" @click="payRe(item, 1)">
                <span>单次续费</span>
              </div>
              |
              <div class="pay-btn" @click="payRe(item, 2)">
                <span>续费一年</span>
              </div>
            </div>
          </div>
          <div class="myVip-item-no" v-if="item.is_valid == 0">
            <div class="myVip-item__top">
              <span class="myVip-title">方案名称</span>
              <span class="myVip-name">{{ item.name }}</span>
              <span class="myVip-date">有效期至：{{ item.valid_date }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无数据 -->
      <div v-else class="emptyData" v-show="isData" style="margin-top: 2rem">
        <img src="@assets/images/emptyData.png" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { userLevel } from "../../api/user";
import { vipReCreate } from "@api/user";
import Vue from "vue";
import { Cell, CellGroup, Loading } from "vant";
import { Image as VanImage } from "vant";
import { MessageBox } from "mint-ui";

Vue.use(VanImage);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Loading);

export default {
  data() {
    return {
      level_one: "",
      level_year: "",
      vipList: [],
      messageOne: "",
      messageYear: "",
      isData: false,
      isLoding: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getVip();
  },
  methods: {
    getVip() {
      userLevel()
        .then((res) => {
          this.isLoding = false;
          this.vipList = res.data.list;
          this.level_one = res.data.level_one;
          this.level_year = res.data.level_year;
          this.messageOne = `<p>续费单次金额为<span class="gold-num">${this.level_one}</span>元，所属VIP课程只享有一次密训上课机会<p>`;
          this.messageYear = `<p>续费一年金额为：<span class="gold-num">${this.level_year}</span>元，VIP自动续签一年<p>`;
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
          this.isLoding = false;
        });
    },
    payRe(item, value) {
      let that = this;
      let params = {
        id: item.level_id,
        type: value,
      };
      if (item.type === 1) {
        MessageBox({
          title: "温馨提示",
          message: "抱歉，线上课程无法续费",
          showCancelButton: true,
        });
      }
      if (item.type === 2) {
        MessageBox({
          title: item.name,
          message: value === 1 ? this.messageOne : this.messageYear,
          showConfirmButton: true,
          showCancelButton: true,
        }).then((status) => {
          if (status === "confirm") {
            vipReCreate(params)
              .then((res) => {
                const order_id = res.data.order_id;
                that.$router.push({
                  path: "/lesson/pay",
                  query: {
                    order_id: order_id,
                    sign: "vip",
                    renewal: "renewal",
                  },
                });
              })
              .catch((err) => {
                that.$dialog.error(err.msg);
              });
          } else {
            return;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss"  scoped>
.myVip-container {
  // 用户信息
  .user-info {
    background-color: #000;
    .base-info {
      background-color: unset;
      .avatar {
        width: 1rem;
        height: 1rem;
        margin-right: 0.2rem;
      }
      .name {
        color: #fff;
      }
    }
  }
  // vip详情
  .myVip-detail {
    padding: 0 0.28rem;
    &-top {
      display: flex;
      justify-content: space-between;
        padding: 0.28rem 0;
      // 标题
      .myVip-title {
        font-size: 0.36rem;
      }
      // 续费记录
      .renew-record {
        font-size: 0.3rem;
      }
    }
      // 列表盒子
    .myVip-card {
      background-color: #fff;
      padding: 0.28rem;
      min-height: 5rem;
      border-radius: 0.2rem;
      // vip列表
      .myVip-item {
        width: 6.4rem;
        height: 2.12rem;
        background-image: url("./images/vip_bg.png");
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.28rem;
        // 列表头部 部分
        &__top {
          height: 1.62rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-left: 0.6rem;
          .myVip-title {
            font-size: 0.24rem;
          }
          // vip名称
          .myVip-name {
            font-size: 0.36rem;
          }
          //日期
          .myVip-date {
            color: #4d4d4d;
          }
        }
        // 底部按钮
        &__bot {
          height: 0.5rem;
          display: flex;
          align-items: center;
          color: #fff;
          .pay-btn {
            flex: 1;
            text-align: center;
          }
        }
      }
      // vip未过期列表
      .myVip-item-no {
        width: 6.4rem;
        height: 1.62rem;
        background-size: cover;
        margin-bottom: 0.28rem;
        background-image: url("./images/vip_bgs.png");
        .myVip-item__top {
          height: 1.62rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-left: 0.6rem;
          .myVip-title {
            font-size: 0.24rem;
          }
          .myVip-name {
            font-size: 0.36rem;
          }
          .myVip-date {
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
</style>